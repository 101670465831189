@font-face {
  font-family: "OpenSans";
  src: url("/Fonts/OpenSans.woff2");
}

body {
  margin: 0;
  font-family: "Montserrat", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #252734;
  color: #c6dad8;
  overflow-y: auto;
  overflow-x: hidden;
}
